import { enableProdMode, APP_INITIALIZER, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { defineCustomElements } from '@xds/components/loader';
import { AppComponent } from './app/app.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app.routing';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { UserService } from './app/application/services/user.service';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { InsightsModule } from '@shared/insights/insights.module';
import { SettingsProvider } from '@shared/services/settingsprovider';
import { AuthenticationInterceptor } from '@shared/authentication/api.interceptor';
import { MsalService } from '@shared/authentication/msal.service';
import { InvitationService } from './app/invitation/services/invitation.service';
import { environment } from '@environments/environment';
import { CanActivateApplications, CanActivateInvitations } from '@shared/guards';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, InsightsModule, ToastrModule.forRoot({
            timeOut: 12000
        }), TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }), ClipboardModule),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeConfiguration,
            deps: [SettingsProvider],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        DatePipe,
        SettingsProvider,
        MsalService,
        UserService,
        InvitationService,
        CanActivateInvitations,
        CanActivateApplications,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideExperimentalZonelessChangeDetection()
    ]
})
  .catch(err => console.error(err));

defineCustomElements();

function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

function initializeConfiguration(settingsProvider: SettingsProvider) {
    return (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            settingsProvider.loadConfiguration().subscribe({
                next: (v) => resolve(v),
                error: (e) => reject()
            })
        });
    };
}
